import React, { Component } from "react";
import { Divider, Container, Image } from "semantic-ui-react";
import Email from "./Email";
import NSWLogo from "../assets/NSWLogoBW.png";
import ExternalLink from "./ExternalLink";

export default class Footer extends Component {
  render() {
    return (
      <div style={{ opacity: 0.6, marginBottom: "1em" }}>
        <Container textAlign="center">
          <Divider hidden section />
          <p>
            Contact <Email />
            <br />
            © 2023 Murrah Hall Preservation Association Inc.
            <br />
          </p>
          <p>Supported by the NSW Government through Create NSW.</p>
          <p>
            <ExternalLink href="https://www.nsw.gov.au/">
              <Image src={NSWLogo} height={80} centered />
            </ExternalLink>
          </p>
        </Container>
        <Divider hidden section />
      </div>
    );
  }
}
