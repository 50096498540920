import React, { Component } from "react";
import { Item } from "semantic-ui-react";

import PropTypes from "prop-types";
import PastEvent from "./PastEvent";

export default class PastEventsList extends Component {

  render() {
    const { hallEvents } = this.props;

    return (
      <Item.Group divided>
        {Object.keys(hallEvents).map(key => {
          const {
            id,
            title,
            description,
            full_description,
            event_date_description,
            cloudinary_image_name
          } = hallEvents[key];
          
          let pastEventProps = {
            title,
            description,
            full_description,
            event_date_description,
            cloudinary_image_name
          };

          return (
            <PastEvent key={id} {...pastEventProps} />
          );
        })}
      </Item.Group>
    );
  }
}

PastEventsList.propTypes = {
  hallEvents: PropTypes.array
};
