import React, { Component } from "react";
import { Item, Container } from "semantic-ui-react";
import PropTypes from "prop-types";
import Event from "./Event";

export default class EventsList extends Component {
  render() {
    const { hallEvents } = this.props;

    return (
      <div>
        <Container>
          Welcome to the Murrah Hall. We are a celebrated community venue
          situated amongst bushland on the Sapphire Coast of NSW, presenting
          professional music and theatre events since 1903.
        </Container>
        <Item.Group divided>
          {Object.keys(hallEvents).map(key => {
            const {
              id,
              title,
              description,
              full_description,
              event_date_description,
              booking_url,
              booking_caption,
              cloudinary_image_name
            } = hallEvents[key];

            let pastEventProps = {
              title,
              description,
              full_description,
              event_date_description,
              booking_url,
              booking_caption,
              cloudinary_image_name
            };

            return <Event key={id} {...pastEventProps} />;
          })}
        </Item.Group>
      </div>
    );
  }
}

EventsList.propTypes = {
  hallEvents: PropTypes.array
};
