import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  Divider,
  Image,
  Icon,
  Container,
  Dropdown
} from "semantic-ui-react";
import logo from "../assets/logo.jpg";
import ExternalLink from "./ExternalLink";
import PropTypes from "prop-types";

export default class HeaderNav extends Component {
  constructor() {
    super();
    this.state = { menuOpen: false };
    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
  }

  onOpenMenu() {
    this.setState({ menuOpen: true });
  }

  onCloseMenu() {
    this.setState({ menuOpen: false });
  }

  falseIfDropdownOpen(active) {
    return active && !this.state.menuOpen;
  }

  render() {
    // Automatically generate the links except for the first one
    // since it has the root instead of it's own route
    const entries = {
      about: "About",
      hiring: "Hall Hire",
      history: "History",
      contribute: "Contribute"
    };

    const dropdown_menu_entries = {
      about: "Overview",
      history: "History",
      contribute: "Contribute"
    };

    const MenuItemCount = Object.keys(entries).length + 2;

    return (
      <div>
        <Link to="/">
          <Image
            src={logo}
            centered
            style={{ marginTop: "2rem", marginBottom:"4rem" }}
            width="340"
            height="345"
            alt="Welcome to the Murrah Hall"
          />
        </Link>
        <div className="ui one row grid container">
          <div
            className="tablet computer only row"
            style={{ marginTop: "1em" }}
          >
            <Container text>
              <Menu widths={MenuItemCount}>
                <Menu.Item
                  name="Events"
                  active={this.props.activeItem === "events"}
                  as={Link}
                  to="/"
                >
                  What&lsquo;s On
                </Menu.Item>
                {Object.keys(entries).map(key => {
                  return (
                    <Menu.Item
                      key={key}
                      name={key}
                      active={this.props.activeItem === key}
                      as={Link}
                      to={"/" + key}
                    >
                      {entries[key]}
                    </Menu.Item>
                  );
                })}
                <Menu.Item
                  style={{
                    width:
                      (0.5 * 100) / MenuItemCount +
                      "%" /* Icons are half the width of other buttons */
                  }}
                  as={ExternalLink}
                  href="https://www.facebook.com/murrah.hall/"
                >
                  <Icon name="facebook" size="large" color="grey" />
                </Menu.Item>
                <Menu.Item
                  style={{
                    width:
                      (0.5 * 100) / MenuItemCount +
                      "%" /* Icons are half the width of other buttons */
                  }}
                  as={ExternalLink}
                  href="https://www.instagram.com/murrah_hall/"
                >
                  <Icon name="instagram" size="large" color="grey" />
                </Menu.Item>
              </Menu>
            </Container>
          </div>
        </div>
        <div
          className="ui one row grid container"
          style={{ marginTop: "2em", justifyContent: "center" }}
        >
          <div className="mobile only row">
            <Menu>
              <Menu.Item
                name="Events"
                active={this.falseIfDropdownOpen(
                  this.props.activeItem === "events"
                )}
                as={Link}
                to="/"
              >
                What&lsquo;s On
              </Menu.Item>
              <Dropdown
                item
                text="About"
                onOpen={this.onOpenMenu}
                onClose={this.onCloseMenu}
              >
                <Dropdown.Menu>
                  {Object.keys(dropdown_menu_entries).map(key => {
                    return (
                      <Dropdown.Item
                        key={key}
                        name={key}
                        active={this.props.activeItem === key}
                        as={Link}
                        to={"/" + key}
                      >
                        {dropdown_menu_entries[key]}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item
                name="hiring"
                active={this.falseIfDropdownOpen(
                  this.props.activeItem === "hiring"
                )}
                as={Link}
                to="/hiring"
              >
                Hire
              </Menu.Item>
              <Menu.Item icon>
                <ExternalLink href="https://www.facebook.com/murrah.hall/">
                  <Icon name="facebook" size="large" color="grey" />
                </ExternalLink>
              </Menu.Item>
              <Menu.Item
                as={ExternalLink}
                href="https://www.instagram.com/murrah_hall/"
              >
                <Icon name="instagram" size="large" color="grey" />
              </Menu.Item>
            </Menu>
          </div>
        </div>
        <Divider hidden section />
      </div>
    );
  }
}

HeaderNav.propTypes = {
  activeItem: PropTypes.string
};
