/* eslint-disable no-irregular-whitespace */
import React, { Component } from "react";
import { Container, Header, Item, Image } from "semantic-ui-react";
import GlessonPDF from "./assets/Judith.pdf";
import MurrarhArchivePDF from "./assets/RecollectionsMurrahHallArticles.pdf";

import CloudinaryImage from "./components/CloudinaryImage";
import ExternalLink from "./components/ExternalLink";

import { cloudinaryPosterImages } from "./photoMetaInfo";
import NSWLogo from "./assets/NSWLogo.png";
import MumbullaLogo from "./assets/MumbullaLogo.jpg";
import BegaLogo from "./assets/BegaLogo.png";
import CoordinareLogo from "./assets/CoordinareLogo.png";
import Pagelayout from "./components/Pagelayout";
const LOGO_HEIGHT = 60;

export default class History extends Component {
  renderContent() {
    return (
      <React.Fragment>
        <p>By Howard Stanley</p>
        <p>
          <em>
            We acknowledge Aboriginal people of the Yuin Nation as the
            traditional custodians of the land on which we work and live and pay
            our respect to Elders both past and present.
          </em>
        </p>
        <p>
          Built in 1903 on land donated to the community by the Gowing family
          the Murrah Hall was, initially, both a school and a community hall
          serving several adjoining localities.  After the school closed,
          the Hall remained empty until the early 1970’s.
        </p>
        <p>
          This current incarnation of the Hall began with chicken wire over the
          windows, road dust and rat poo covering the floor. A group of recently
          arrived people from Melbourne and Sydney saw it as, potentially, an
          ideal place to rehearse plays. The group included Director Wendy
          Blay, later Tucker, Georgie Adamson, Bruce Angus and Arthur Gately.
          Georgie, Bruce and in the mid 90’s, Arthur, all served as
          Presidents of the Hall.
        </p>
        <p>
          In wanting to attract more locals to their cultural activities, as
          well as help in beginning to restore the Hall, one of the passing
          locals offered to Bruce the following advice: <br />
          <em>
          “Just paint the roof red, mate. That’ll get ’em interested, paint the roof red.”
          </em>{" "}
          So they did.
        </p>
        <p>
          In the 1980’s and 90’s, the then President John McViety,
          oversaw a musical and building reconstruction renaissance at the Hall.
          There were featured visiting artists such as The Wararumpi Band, The
          Bondi Cigars, The Gadflys, Colin Hay and many, many locals, like The
          Mullamatics, The Fabulous Wilt Brothers. The locally born resident
          and international jazz diva, the late ‘Madam’ Pat
          Thompson, also called the Murrah home, to the delight of locals. Her
          homage portrait by Lowell Tarling is in the Hall. The Hall’s
          Supper Room photos (courtesy of the late Alf Gowing) show those
          generations: restoring, rebuilding and at play.
        </p>
        <p>
          In 2002 a community grant on behalf of the Murrah committee and the
          Yuin Nation resulted in  a large mural in the Hall identifying
          The Murrah as being part of their community and their nation. This was
          done under the Presidency of Pauleen Harris.
        </p>
        <p>
          Since 2004 the Hall  has been administered by the Murrah Hall
          Preservation Association Inc. on behalf of the Bega Valley Shire
          Council . The handover was the result of increased overheads and the
          cost of basic capital works needed to keep the Hall running smoothly
          and safely, which BVSC has done.
        </p>
        <p>
           Like all previous committees, this one is committed to the
          continued development and preservation of the Murrah Hall as a
          community resource and as an all age family friendly venue: attracting
          amazing music, creating memorable theatre, supporting and showcasing
          local creatives. Being a portal to the world for our audiences. 
        </p>
        <p>
          The current Committee also actively embarked on a long term plan of
          upgrading the Hall’s facilities, the Murrah Metamorphosis, and
          becoming a community-wide artist-friendly asset, with the introduction
          of basic portable lighting and seating via a Mumbulla Foundation
          grant. The hilarious gender based reversal (directed by H.Stanley) of
          a 1947 Lone Ranger Radio program, created funding for portable rostra,
          enabling new staging and seating possibilities for theatre, cabaret
          and music performances.
        </p>
        <p>
          More than a decade of catering-based fundraising and a $5,000 donation
          from committee member, performer and ex-President, the late Premda
          Lowson, allowed the current committee, with volunteer labour and
          support from local trades-based individuals and businesses, to build a
          secure dressing and storage room extension onto the rear of the Hall
          in 2018 - The Hall’s 115th Birthday.
        </p>
        <p>
          With an additional Mumbulla Foundation grant for catering pots and
          saucepans and, because of the volunteer-built new extension, the
          committee partnered with South East Arts, as project managers, to
          successfully apply to the NSW Create Regional Cultural Fund for:
          $70,000.00 for a portable P.A., LED lighting, audio-visual and
          studio lighting equipment, a new portable stage carpet, and 
          funding for local technical based training.
        </p>
        <p>And not to forget: This website too!</p>
        <p>&nbsp;</p>
        <Header as="h3">2019 - 2023</Header>
        <p>
        First the Fires and then COVID created an ongoing fraught time for all. <br />
        In early February 2020, between emergency evacuations,
        the Hall successfully put on their first free entry, free food and music event with “The Strides”.
        This was supported by an American Philanthropic organisation.<br />
        It was a singularly memorable night for the hundreds who attended.
        </p>
        <p>
        The Murrah Hall Committee adapted to COVID restrictions on our disaster affected 
        community by moving performances outside onto the deck of 5 tonne pantech truck
        (with donated safety stairs from Bermagui Seasiders) to become the only community
          venue in the region to be able to offer large numbers of all age audiences safe,
           socially distanced professional entertainment funded from:
<ul>
<li>Destination NSW/ARIA’s Great Southern Nights - 5 shows</li>
<li>Coordinaire and BVSC Community Disaster Recovery funding - 3 Free Entry all-age inclusive Outdoor Food and Music events.</li>
<li>The committee partnered with Bermagui Seasiders for Revive (FRRR community recovery funding)
</li>
</ul>
In total the Murrah Hall (outdoors) entertained nearly 2,500 locals and paid the fees for more than 90 performers and production personnel.

        </p>
        <p>
        In time for the Hall’s 120th Celebrations, the Hall received a much appreciated 
        and needed one-off BVSC grant to replace the north wall weatherboards and hall entry doors, 
        install underfloor hall insulation, upgrade the fuse board and, importantly, paint the Hall and surrounds.

        </p>
        <p>
        With a successful second Create NSW Cultural Infrastructure grant of $27,500.00, 
        to complete the Murrah Metamorphosis - Indoors project, the Hall now has sixteen 
        2 X 1 metre adjustable height portable indoor/outdoor staging units (also for community hire) 
        and now features in a Far South Coast first: acoustic panelling, making it the safest listening Community Hall in the region. 
        The nature of the installation allows the Hall to be ‘tuned’ if needed.
        </p>

        <p>
        In response to community need and desire and based on the experiences with the
         feedback of two near unanimous audience surveys, the Murrah Metamorphosis continues - Outdoors.
With BVSC permission, plans for a rear of Hall mini amphitheatre, an upgraded Cantina BBQ
 and shading are in process as suitable projects for external funding applications in order to 
 create the first integrated indoor/outdoor community hall cultural precinct on the Far South Coast.<br />
We’ve come a long since that first community call to action:

        </p>
        <p>
          <em>
          “Just paint the roof red, mate. That’ll get ’em
            interested, paint the roof red.”
          </em>
        </p>
        <p>&nbsp;</p>
        <p>
          The Murrah Committee acknowledges the decades of contributions from
          the volunteers and families of this remarkable community. On behalf of
          the Hall’s audiences and artists, we express gratitude for
          all the philanthropic, state and local government support and
          especially local business in their recognition and support for this
          unique Far South Coast community cultural asset.
        </p>
        <div style={{ paddingTop: "1em", paddingBottom: "1em" }}>
          <div className="ui two column grid">
            <div className="row">
              <div className="column">
                <ExternalLink href="https://www.begavalley.nsw.gov.au">
                  <Image src={BegaLogo} height={LOGO_HEIGHT} />
                </ExternalLink>
              </div>
              <div className="column">
                <ExternalLink href="https://www.nsw.gov.au/">
                  <Image src={NSWLogo} height={LOGO_HEIGHT} />
                </ExternalLink>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <ExternalLink href="http://mumbulla.org.au/">
                  <Image src={MumbullaLogo} height={LOGO_HEIGHT} />
                </ExternalLink>
              </div>
              <div className="column">
                <ExternalLink href="https://www.coordinare.org.au/">
                  <Image src={CoordinareLogo} height={LOGO_HEIGHT} />
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
        <Header as="h3">
        Historical information:
          </Header>
        <p>
         
          Detailing the Community recovery and rebuilding of the Murrah Hall:&nbsp;
          <ExternalLink href={GlessonPDF}>
            ‘The Early Years’ by Judith Gleeson (pdf).
          </ExternalLink>
        </p>
        <p>
        The <ExternalLink href={MurrarhArchivePDF}>‘Murrah Archive’ (pdf)</ExternalLink> is an irreplaceable community collection.
        </p>
        <p>
        Articles by Dr Richard Reid on the Murrah Archive that originally appeared in ‘Recollections’ issues 29, 31 and 32
         (available online at &nbsp;
          <ExternalLink href="http://www.bit.ly/Recollections29">http://www.bit.ly/Recollections29</ExternalLink>, &nbsp;
          <ExternalLink href="http://www.bit.ly/Recollections31">http://www.bit.ly/Recollections31</ExternalLink> and &nbsp;
          <ExternalLink href="www.bit.ly/Recollections32">www.bit.ly/Recollections32</ExternalLink>)
        </p>
        <br />
        <Header as="h3">The story behind our Logo</Header>
        <p>
          In 2014 Reg Mombassa and Pete O’Doughety’s band Dog
          Trumpet with Bernie Hayes and the late, great, legend, Drummer &
          Journo, Iain Shedden (pictured), first played at the Murrah Hall to
          resounding success on both sides of the lights.
        </p>
        <CloudinaryImage name="RegHallPhoto" width={480} />
        <span style={{ opacity: 0.6 }}>Photograph by Roger Stuart 2016</span>
      
        <p />
        <p>
          Their return in 2016 was marked by a specially drawn poster of the
          Murrah Hall by Reg for that second show:
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CloudinaryImage name="dog_trumpet_poster" width={280} />
        </div>
      </React.Fragment>
    );
  }
  render() {
    return (
      <Pagelayout activeItem="history">
        <Container text>
          <Header as="h1">History</Header>
          <Item.Group>
            <Item>
              <Item.Content>{this.renderContent()}</Item.Content>

              <Item.Image
                style={{
                  // This will have fixed width set depending on screen size
                  // so use margin instead of padding so we don't squish the images down.
                  marginLeft: "2rem",
                  marginTop: "-1rem",
                }}
              >
                {/*
                Using Item.Image (and the parent Item.Group) purely for
                the free responsive image sizing.
                However, we don't want the automatic column flow on mobile so
                we will dynamically hide the sidebar for smaller screens to
                and show the images separately at the bottom afterwards.
               */}
                <div className="ui one column grid">
                  <div className="tablet computer only column">
                    {cloudinaryPosterImages}
                  </div>
                </div>
              </Item.Image>
            </Item>
          </Item.Group>
          <div className="ui two column grid container">
            {cloudinaryPosterImages.map((poster, index) => (
              <div className="mobile only column" key={index}>
                {poster}
              </div>
            ))}
          </div>
        </Container>
      </Pagelayout>
    );
  }
}
