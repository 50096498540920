import Axios from "axios";

class HallEventsApi {
  static getAllCurrentHallEvents() {
    return Axios.get("/api/hall_events").then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
  }

  static getAllPastHallEvents() {
    return Axios.get("/api/hall_events?past").then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
  }
}

export default HallEventsApi;
