import HallEventsApi from '../api/HallEventsApi';
import * as types from './actionTypes';

export function loadCurrentHallEvents() {
  return function(dispatch) {
    dispatch(loadCurrentHallEventsLoading());
    
    return HallEventsApi.getAllCurrentHallEvents().then(hallEvents => {
      dispatch(loadCurrentHallEventsSuccess(hallEvents));
    }).catch(error => {
      dispatch(loadCurrentHallEventsFailure(error));
    });
  };
}

function loadCurrentHallEventsLoading(){
  return {type: types.LOAD_CURRENT_HALL_EVENTS_LOADING};
}

function loadCurrentHallEventsSuccess(hallEvents) {
  return {type: types.LOAD_CURRENT_HALL_EVENTS_SUCCESS, hallEvents};
}

function loadCurrentHallEventsFailure(error) {
  return {type: types.LOAD_CURRENT_HALL_EVENTS_FAILURE, error};
}
