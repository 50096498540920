import React, { Component } from "react";
import PropTypes from "prop-types";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";

export default class Pagelayout extends Component {
  render() {
    return (
      <div>
        <HeaderNav activeItem={this.props.activeItem} />
        {!!this.props.children && this.props.children}
        <Footer />
      </div>
    );
  }
}

Pagelayout.propTypes = {
  children: PropTypes.node,

  activeItem: PropTypes.string
};
