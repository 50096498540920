import React from "react";
import {Container} from "semantic-ui-react";
import PropTypes from "prop-types";

export default function ErrorMessage(props) {
  return (
    <Container textAlign="center">
      Something went wrong (Error {props.error.response.status}). Please contact us to let us know.
    </Container>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.object
};
