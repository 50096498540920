import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./About";
import { loadCurrentHallEvents } from "./actions/currentHallEventActions";
import { loadPastHallEvents } from "./actions/pastHallEventActions";
import Contribute from "./Contribute";
import CurrentEvents from "./CurrentEvents";
import Gallery from "./Gallery";
import Hiring from "./Hiring";
import History from "./History";
import NotFound from "./NotFound";
import PastEvents from "./PastEvents";
import { allPhotosHash } from "./photoMetaInfo";
import configureStore from "./store/configureStore";


const store = configureStore();

store.dispatch(loadCurrentHallEvents());
// We only want to fetch once.. If they want new events info they can reload the page.
// Even if there is an error.
let loadPastOnce = true;
function dispatchLoadPastHallEvents() {
  if(loadPastOnce) {
    store.dispatch(loadPastHallEvents());
    loadPastOnce = false;
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" exact component={CurrentEvents} />
            <Route
              path="/past"
              exact
              // react router requires bound functions to pass down props.
              // eslint-disable-next-line react/jsx-no-bind
              render={routeProps => (
                <PastEvents
                  {...routeProps}
                  dispatchLoadPastHallEvents={dispatchLoadPastHallEvents}
                />
              )}
            />
            <Route path="/about" exact component={About} />
            {
              // Only allow navigation to valid poster image names
            }
            <Route
              path={
                "/gallery/:name(" +
                Object.keys(allPhotosHash).join("|") +
                ")"
              }
              component={Gallery}
            />
            
            <Route path="/hiring" exact component={Hiring} />
            <Route path="/history" exact component={History} />
            <Route path="/contribute" exact component={Contribute} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
