import React, { Component } from "react";
import ExternalLink from "./ExternalLink";
import Markdown from "markdown-to-jsx";
import PropTypes from "prop-types";

// Simple wrapper class provides hard coded options for the whole site.
export default class MarkdownExternalLink extends Component {
  render() {
    const {options, ...otherProps} = this.props;

    return (
      <Markdown
        options={{
          overrides: {
            a: {
              component: ExternalLink
            }
          }
        }}
        {...otherProps}
      /> 
    );
  }
}

MarkdownExternalLink.propTypes = {
  children: PropTypes.node,
  options: PropTypes.object,
};
