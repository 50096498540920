import * as types from '../actions/actionTypes';

export default function pastHallEventsReducer(state = [], action) {
  switch(action.type) {
    case types.LOAD_PAST_HALL_EVENTS_LOADING:
    
      return  {...state, isFetching: true};

    case types.LOAD_PAST_HALL_EVENTS_SUCCESS:
      return {...state, pastHallEvents: action.pastHallEvents, isFetching: false};

    case types.LOAD_PAST_HALL_EVENTS_FAILURE:
      return {...state, error: action.error, isFetching: false};
    default:
      return state;
  }
}

