import React, { Component } from "react";
import {
  Container,
  Header,
  Image
} from "semantic-ui-react";

import { allPhotosHash } from "./photoMetaInfo";
import Pagelayout from "./components/Pagelayout";
import PropTypes from "prop-types";

export default class Gallery extends Component {
  render() {
    const name = this.props.match.params.name;
    return (
      <Pagelayout activeItem="gallery">
        <Container text>
          <Header as="h1">Gallery</Header>
          <Header as="h3">{allPhotosHash[name].title}</Header>
        </Container>
        <Image src={allPhotosHash[name].url} alt={name} centered/>
      </Pagelayout>
    );
  }
}

Gallery.propTypes = {
  match: PropTypes.object,
};
