import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import currentHallEventsReducer from "../reducers/currentHallEventsReducer";
import pastHallEventsReducer from "../reducers/pastHallEventsReducer";


export default function configureStore(state = { hallevents: [] }) {
  return createStore(
    combineReducers({currentHallEvents: currentHallEventsReducer,
    pastHallEvents: pastHallEventsReducer}),
    applyMiddleware(thunk)
  );
}
