import React, { Component } from "react";
import { Container, Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loading from "./components/Loading";
import EventsList from "./components/EventsList";
import Error from "./components/ErrorMesage";

import { connect } from "react-redux";
import Pagelayout from "./components/Pagelayout";

class CurrentEvents extends Component {
  render() {
    const showList =
      this.props.hallEvents && !this.props.isFetching && !this.props.error;
    return (
      <Pagelayout activeItem="events">
        <Container text>
          {this.props.isFetching && <Loading />}
          {this.props.error && <Error error={this.props.error} />}
          {showList && <EventsList hallEvents={this.props.hallEvents} />}

          <Divider hidden section />
          <Container textAlign="center">
            <Link to="/past">View past events</Link>
          </Container>
        </Container>
      </Pagelayout>
    );
  }
}

CurrentEvents.propTypes = {
  isFetching: PropTypes.bool,
  hallEvents: PropTypes.array,
  error: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.currentHallEvents.isFetching,
    hallEvents: state.currentHallEvents.hallEvents,
    error: state.currentHallEvents.error
  };
}

export default connect(mapStateToProps)(CurrentEvents);
