import React, {Component} from "react";
import PropTypes from "prop-types";

export default class ExternalLink extends Component {
  render() {
    const {href, ...otherProps} = this.props;
    return (
      <a href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...otherProps}
      >
        {this.props.children}
      </a>
    );
  }
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};
