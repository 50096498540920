import React, { Component } from "react";
import { Header, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ExternalLink from "./components/ExternalLink";
import AboutBlurb from "./components/AboutBlurb";
import CloudinaryImage from "./components/CloudinaryImage";
import HallLayoutPDF from "./assets/MurrahDimensions.pdf";
import Pagelayout from "./components/Pagelayout";
// The images inside the columns should be the same width
const IMAGE_WIDTH = 400;

class About extends Component {
  render() {
    return (
      <Pagelayout activeItem="about">
        <Container text>
          <Header as="h1">About</Header>
          <AboutBlurb />
          &nbsp;
          <br />
          <CloudinaryImage name="MurrahHall2019" width={IMAGE_WIDTH} />
          <Header as="h2">Getting Here</Header>
          <p>
            The Hall is situated approximately 20 mins drive South of Bermagui
            and approximately 25 mins north of Tathra.
          </p>
          <Header as="h3">Location</Header>
          <p>2989 Tathra-Bermagui Rd, Murrah NSW 2546</p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3205.846557546064!2d150.02649831528237!3d-36.533696080004276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b3e64b37f076d2b%3A0xa3303674fec10cdf!2s2989+Tathra-Bermagui+Rd!5e0!3m2!1sen!2sau!4v1553118894214"
            width={IMAGE_WIDTH}
            height="360"
            frameBorder={0}
            style={{ border: 0 }}
            title="Map location preview"
            allowFullScreen
          />
          <Header as="h2">Facilities</Header>
          <ul>
            <li>Large onsite car park with additional car parking nearby.</li>
            <li>Disabled ramp entry and toilet facilities.</li>
            <li>Floodlights - car park</li>
            <li>Mobile phone reception</li>
            <li>
              Semi-commercial equipped Kitchen (crockery etc.) & commercial
              catering pots & saucepans
            </li>
            <li>A secure dressing room with on stage access</li>
            <li>
              Indoor seated audience numbers are between 85 (seats) - 120 (inc.
              side benches).
            </li>
            <li>
              Dance music events have chairs located between Hall and Cantina,
              outside, for a circulating audience of up to 250.
            </li>
          </ul>
          <p>
            <strong>
              Professional Hirers must show evidence of their own Public
              Liability Insurance before hire date is confirmed.
            </strong>
          </p>
      <p>
          <Link to={"gallery/hall_view_from_stage_full"} >
            <CloudinaryImage
              name="hall_view_from_stage_full"
              width={IMAGE_WIDTH}
            />
          </Link>
          </p>
          <Link to={"gallery/hall_outdoors"} >
            <CloudinaryImage name="hall_outdoors" width={IMAGE_WIDTH } />
          </Link>
          <Header as="h2">Equipment</Header>
          <ul>
            <li>Portable PA</li>
            <li>Full LED Lighting coverage</li>
            <li>Professional operators</li>
            <li>Portable rostra</li>
          </ul>
          <Header as="h2">Support</Header>
          <ul>
            <li>
              Promotion support via Murrah Hall Facebook, email groups &
              media contact list
            </li>
            <li>
              Advice and support with accommodation, ticketing and media
              coverage
            </li>
            <li>Catering by the volunteer committee members</li>
          </ul>
          <ExternalLink href={HallLayoutPDF}>
            <CloudinaryImage name="HallLayout" width={IMAGE_WIDTH} />
          </ExternalLink>
        </Container>
      </Pagelayout>
    );
  }
}

export default About;
