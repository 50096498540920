import React from 'react';
import { Container, Dimmer, Loader } from "semantic-ui-react";

export default function Loading() {
    return (
      <Container text>
        <Dimmer active inverted>
          <Loader content="Loading" />
        </Dimmer>
      </Container>
    );
  }

