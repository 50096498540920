import React, { Component } from "react";
import { Header, Container } from "semantic-ui-react";
import Pagelayout from "./components/Pagelayout";
import CloudinaryImage from "./components/CloudinaryImage";
import ExternalLink from "./components/ExternalLink";
import Email from "./components/Email";


export default class Contribute extends Component {
  render() {
    return (
      <Pagelayout activeItem="contribute">
        <Container text>
        <Header as="h1">Contribute</Header>
        <p>
          There are various ways in which you can contribute to maintaining the
          Murrah Hall as a lively local all age hotspot beloved by generations.
        </p>

        <CloudinaryImage name="MurrahCooking" width={480} />
        <p>
          <strong>Pic by Jimmy Zero</strong>
        </p>
        <Header as="h3">Volunteering</Header>

        <p>
          This is a valuable contribution to the Hall &ndash; and it is fun,
          which is why we do it.&nbsp;&nbsp;
        </p>

        <p>
          You don&rsquo;t have to be a Committee member to volunteer, and the
          extent of your participation is always appreciated whether it&rsquo;s
          an hour, a day, an evening (or part thereof) for catering, serving, or
          a working bee.
        </p>

        <p>
          Generally, volunteers receive free entry to the event for which they
          are volunteering and a serving of curry.&nbsp; Working bees are
          usually catered.
        </p>

        <p>
          All volunteers at the Murrah Hall are covered by 3rd party insurance.
        </p>
        <p>
          <br />
        </p>
        <Header as="h2">How to Volunteer</Header>

        <p>Send us your contact details via <Email /></p>
        <p>
          Attend an event and talk to the other volunteers there - leave your
          contact details.
        </p>
        <p>
          Or let us know via{" "}
          <ExternalLink href="https://www.facebook.com/murrah.hall/">
            Facebook
          </ExternalLink>
        </p>

        <h2>
          <strong>What volunteering opportunities are there?</strong>
        </h2>

        <Header as="h3">Catering</Header>

        <p>
          Typically this involves donating a cake, slices, biscuits, snacks,
          sweet or savoury, to be sold at the Hall music events.&nbsp; This is
          our main fundraising activity and also part of our &lsquo;duty of
          care&rsquo; for our audiences.
        </p>

        <p>
          The Murrah can reimburse you for the cost of materials and can collect
          the goodies from you in the event that you can&rsquo;t make it to the
          Hall.
        </p>

        <p>
          Also, we are always looking for new signature dishes of amazing
          wholesomeness, like our Murrah curries, for serving to large numbers.
          Do you have a suitable recipe or suggestion?
        </p>

        <Header as="h3">Servers</Header>

        <p>
          This involves serving curries to the audience and artists during the
          Hall music events, taking payments, keeping an eye on the cash box,
          and may include kitchen, sink and clean up duties.
        </p>

        <Header as="h3">Working Bees</Header>

        <p>
          Periodically the Murrah has working bees to clean and organise,
          restore and preserve our Hall and surrounds.
        </p>

        <Header as="h3">Spread the Word</Header>

        <p>
          Would you be prepared to help promote Murrah Hall events?&nbsp; Hang
          posters? Join the Murrah email forum, share on Facebook, or on your
          own forums?&nbsp; We&rsquo;d be grateful &ndash; and so are the
          artists.
        </p>

        <Header as="h3">Technical Support</Header>

        <p>
          If you have technical skills in sound and lighting for music and
          theatre events, the Murrah needs you.&nbsp; And if you don&rsquo;t,
          but are interested in acquiring these skills, then check out the list
          below let us know where you think you could contribute.
        </p>

        <p>Upcoming workshops include:</p>

        <ul>
          <li>Sound equipment storage and set-up</li>
          <li>Sound operation</li>
          <li>Lighting operation</li>
          <li>
            Audio Visual staging and operation for small meetings and
            presentations.
          </li>
          <li>Studio photography lighting</li>
        </ul>

        <Header as="h3">Accommodation</Header>

        <p>
          Interested in hosting or billeting visiting artists?&nbsp; This
          involves accommodating 1-6 artists for one night.&nbsp; Catering is
          minimal as the Murrah provides a meal for the artists at the event.
        </p>

        <Header as="h1">Donations</Header>

        <p>
          Apart from the donation of valuable volunteer time, the Hall Committee
          is always interested in suitable donations. Our needs are many and
          varied ranging from items for specific projects to
          &lsquo;loaners&rsquo; for theatre productions. These items are usually
          specifically requested on the Murrah email forum, and our storage
          space is limited, but do let the committee know if you have something
          in your garage that would be of service to the Hall.
        </p>
        <p>
          And of course we are always grateful for monetary donations or fund
          raising for the direct benefit of the Hall.{" "}
        </p>
        </Container>
      </Pagelayout>
    );
  }
}
