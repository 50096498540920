/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import { Container, Header, Item } from "semantic-ui-react";
import ExternalLink from "./components/ExternalLink";
import MurrahLighting from "./assets/MurrahLighting.pdf";
import MurrahEquipment from "./assets/MurrahHallTechEquipmentList.pdf";
import Email from "./components/Email";
import PropTypes from "prop-types";
import Pagelayout from "./components/Pagelayout";
import { cloudinaryHallImages } from "./photoMetaInfo";

class PriceTable extends Component {
  render() {
    return (
      <table style={{ marginBottom: "1rem", borderSpacing: "0px" }}>
        {this.props.children}
      </table>
    );
  }
}
PriceTable.propTypes = {
  children: PropTypes.node,
};

class PriceTableHeading extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th colSpan="2" style={{ textAlign: "left" }}>
            <strong>{this.props.children}</strong>
          </th>
        </tr>
      </thead>
    );
  }
}
PriceTableHeading.propTypes = {
  children: PropTypes.node,
};

class PriceTableBody extends Component {
  render() {
    return <tbody>{this.props.children}</tbody>;
  }
}
PriceTableBody.propTypes = {
  children: PropTypes.node,
};

class PriceTableEntry extends Component {
  render() {
    return (
      <tr>
        <td width={400}>
          {this.props.desc}
          {this.props.children}
        </td>
        <td style={{ verticalAlign: "top" }}>{this.props.price}</td>
      </tr>
    );
  }
}
PriceTableEntry.propTypes = {
  desc: PropTypes.string,
  price: PropTypes.string,
  children: PropTypes.node,
};

export default class Hiring extends Component {
  renderHiringText() {
    return (
      <div>
        <p>
          <strong>
            The Murrah Hall is a Bega Valley Shire Council owned community hall,
            staffed and run by a committee of volunteers.
          </strong>
        </p>
        <Header as="h2">
          For <em>all</em> Hall bookings and enquiries contact:
        </Header>
        <p>
          Mary McLean, Bookings Officer
          <br />
          Ph: (02) 6494 0259
          <br />
          Email: <Email />
        </p>
        <p>
          Post: Murrah Hall Committee
          <br />
          PO Box 191
          <br />
          Bermagui, NSW, 2546
        </p>
        <Header as="h2">Conditions of Hall Hire</Header>
        <p>
          All intending professional hirers must provide evidence of their 3rd
          Party insurance.
        </p>
        <p>
          <strong>Hall Hire</strong>: To secure the hall for your event we
          require upon completion of BVSC hall hire agreement, full payment of
          hall hire fees (plus GST) off an issued BVSC invoice, at least two
          weeks prior to the booking date. Murrah Booking Officer to supply
          details.
        </p>
        <p>
          <strong>Hall fees and charges</strong>: to be paid directly to Murrah
          Hall committee.
        </p>
        In the case of a cancelled event due to unforeseen circumstances, BVSC
        will retain the full hall hire fee. Event entry price must not exceed
        $40 per person as per Bega Valley Shire Council's (BVSC) licence with
        One Music.
        <Header as="h2">Venue Essentials</Header>
        <p>
          <strong> Capacity. </strong> Indoors seated audience numbers are between 85 (seats) - 120 (inc.
          side benches). Dance music events have chairs located between Hall and
          the outside Cantina for a circulating audience of up to 250.
        </p>
        <p>Music events at the Hall are ‘all ages and inclusive’.</p>
        <p>
          The Committee encourages free admission for children under 16 y.o. and
          a HCC concession policy where possible.
        </p>
        <p>All door takings revert to the hirer.</p>
        <p><strong>The Murrah Hall has no liquor licence.</strong></p>
        <Header as="h2">For Profit Hall Hire Rates</Header>
        <PriceTable>
          <PriceTableHeading>Nights</PriceTableHeading>
          <PriceTableBody>
            <PriceTableEntry desc="Sunday to Thursday:" price="$90" />
            <PriceTableEntry price="$160">
              Friday & Saturday Night:
            </PriceTableEntry>
          </PriceTableBody>
        </PriceTable>
        <PriceTable>
          <PriceTableHeading>Days</PriceTableHeading>
          <PriceTableBody>
            <PriceTableEntry desc="Saturday or Sunday Afternoon:" price="$90" />
          </PriceTableBody>
        </PriceTable>
        <Header as="h3">Additional Hall Hire Fees and Charges</Header>
        <PriceTable>
          <PriceTableHeading>Professional only</PriceTableHeading>
          <PriceTableBody>
            <PriceTableEntry desc="Cleaning:" price="$30 " />
            <PriceTableEntry
              desc="Facebook boost week prior (recommended):"
              price="$35 "
            />
          </PriceTableBody>
        </PriceTable>
        <PriceTable>
          <PriceTableHeading>
            {" "}
            Community Hire: Weddings, Social Celebrations, conferences, funerals
            and memorials
          </PriceTableHeading>
          <PriceTableBody>
            <PriceTableEntry>
              Email: <Email />
            </PriceTableEntry>
          </PriceTableBody>
        </PriceTable>
        <Header as="h3">Equipment Hire - onsite only</Header>
        <PriceTable>
          <PriceTableHeading>Audio</PriceTableHeading>
          <PriceTableBody>
            <PriceTableEntry
              desc="Full portable PA"
              price="$200"
            />
            <PriceTableEntry>
            Payment of local operators is the hirer’s responsibility
            </PriceTableEntry>
            <PriceTableEntry
              desc="Small portable PA "
              price="$150"
            />
             <PriceTableEntry>
              <ExternalLink href={MurrahEquipment}>Murrah hall equipment list</ExternalLink>
            </PriceTableEntry>
          </PriceTableBody>
        </PriceTable>
        <p>
          <strong>Please note</strong>
          <br />
          The equipment is not a fixed in-house system.
          <br />
          It’s portable, requiring a supervised set up and pack up - hence these
          rates.
        </p>
        <PriceTable>
          <PriceTableHeading>Lighting</PriceTableHeading>
          <PriceTableBody>
            <PriceTableEntry price="$100">
              Full system with our operator: <br />
            </PriceTableEntry>
            <PriceTableEntry
              desc="Full system with your operator:"
              price="$50"
            />
            <PriceTableEntry>
              <ExternalLink href={MurrahLighting}>Lighting layout</ExternalLink>
            </PriceTableEntry>
          </PriceTableBody>
        </PriceTable>
        <PriceTable>
          <PriceTableBody>
            <PriceTableEntry price="$50">Portable Rostra</PriceTableEntry>
          </PriceTableBody>
        </PriceTable>
        <strong>
          For more technical details, specs and shapes: <Email />
        </strong>
        <Header as="h2">Murrah Artist Support</Header>
        <Header as="h4">Marketing at no cost</Header>
        <ul>
          <li>
            Murrah Hall Facebook page (2000+ likes) plus 3 email lists (400+), Murrah Hall webpage (21,0000+ views)
          </li>
          <li>
          Poster placement: (25 x A3 15 x A4) - you provide posters (locally printed: A3 $1.00/A4 $0.50 per sheet plus $20 fuel)

          </li>
          <li>
          Lodging press release & high res. pic (or providing media contact list) - if needed

          </li>
          <li>
          Merchandising: Supper room space available

          </li>
        </ul>
        <Header as="h4">Artist Support - Free on the day</Header>
        <ul>
          <li>Door or gate entry with committee members</li>
          <li>Free snacks/meals for performers and crew</li>
        </ul>
        <Header as="h4">Accommodation</Header>
        <ul>
          <li>
          Committee to source (where possible) low cost accommodation/billets if needed.

          </li>
        </ul>
        <Header as="h4">Catering</Header>
        As a Duty of Care fundraising for the Hall, the Murrah Hall Committee usually cater ‘For Profit’ events variously offering Murrah Curries, Soups or Pasta, slices and gold coin tea & coffee. 
      </div>
    );
  }
  render() {
    return (
      <Pagelayout activeItem="hiring">
        <Container text>
          <Header as="h1">Hall Hire at the Murrah</Header>
          <Item.Group>
            <Item>
              <Item.Content>{this.renderHiringText()}</Item.Content>

              <Item.Image
                style={{
                  // Add some margin between the images an the text.
                  // This will have fixed width set depending on screen size
                  // so use margin instead of padding so we don't squish the images down.
                  marginLeft: "2rem",
                  marginTop: "-1rem",
                }}
              >
                {/*
                Using Item.Image (and the parent Item.Group) purely for
                the free responsive image sizing.
                However, we don't want the automatic column flow on mobile so
                we will dynamically hide the sidebar for smaller screens to
                and show the images seperately at the bottom afterwards.
               */}
                <div className="ui one column grid">
                  <div className="tablet computer only column">
                    {cloudinaryHallImages}
                  </div>
                </div>
              </Item.Image>
            </Item>
          </Item.Group>
          <div className="ui two column grid container">
            {cloudinaryHallImages.map((poster, index) => (
              <div className="mobile only column" key={index}>
                {poster}
              </div>
            ))}
          </div>
        </Container>
      </Pagelayout>
    );
  }
}
