import React, { Component } from "react";
import CloudinaryImage from "./CloudinaryImage";
import { Link } from "react-router-dom";

import { Item } from "semantic-ui-react";
import PropTypes from "prop-types";
import MarkdownExternalLink from "./MarkdownExternalLink";

export default class PastEvent extends Component {
  constructor() {
    super();
    this.state = { full_description: false };
    this.toggleDescription = this.toggleDescription.bind(this);
  }

  toggleDescription() {
    this.setState({ full_description: !this.state.full_description });
  }

  render() {
    const {
      title,
      description,
      full_description,
      event_date_description,
      cloudinary_image_name
    } = this.props;

    const toggle_button_text = this.state.full_description
      ? "Show less…"
      : "Show more…";

    return (
      <Item>
        <Item.Image size="medium">
          {cloudinary_image_name && (
            <CloudinaryImage
              name={cloudinary_image_name}
              width={300}
              height={300}
              crop="fit"
              style={{
                // Fight the semantic item image settings
                // but keep their spacing.
                height: "initial",
                width: "initial",
                marginLeft: "auto"
              }}
            />
          )}
        </Item.Image>

        <Item.Content>
          <Item.Header style={{ lineHeight: 1.2, marginBottom: "1em" }}>
            {title}
          </Item.Header>
          <Item.Meta>{event_date_description}</Item.Meta>
          <Item.Description>
            <MarkdownExternalLink>{description}</MarkdownExternalLink>
            {this.state.full_description && (
              <MarkdownExternalLink>{full_description}</MarkdownExternalLink>
            )}
          </Item.Description>
          {!!full_description && (
            <Item.Extra>
              <Link to="/past" onClick={this.toggleDescription}>
                {toggle_button_text}
              </Link>
            </Item.Extra>
          )}
        </Item.Content>
      </Item>
    );
  }
}

PastEvent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  full_description: PropTypes.string,
  event_date_description: PropTypes.string,
  cloudinary_image_name: PropTypes.string
};
