import React, { Component } from "react";
import { Container, Divider } from "semantic-ui-react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PastEventsList from "./components/PastEventsList";
import Pagelayout from "./components/Pagelayout";
import Loading from "./components/Loading";
import Error from "./components/ErrorMesage";

class PastEvents extends Component {
  componentDidMount() {
    this.props.dispatchLoadPastHallEvents();
  }

  render() {
    const showList =
      this.props.pastHallEvents && !this.props.isFetching && !this.props.error;

    return (
      <Pagelayout activeItem="events">
        <Container text>
          {this.props.isFetching && <Loading />}
          {this.props.error && <Error error={this.props.error} />}
          {showList && <PastEventsList hallEvents={this.props.pastHallEvents} />}

          <Divider hidden section />
          <Container textAlign="center">
            <Link to="/">View upcoming events</Link>
          </Container>`
        </Container>
      </Pagelayout>
    );
  }
}

PastEvents.propTypes = {
  isFetching: PropTypes.bool,
  pastHallEvents: PropTypes.array,
  dispatchLoadPastHallEvents: PropTypes.func.isRequired,
  error: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.pastHallEvents.isFetching,
    pastHallEvents: state.pastHallEvents.pastHallEvents,
    error: state.pastHallEvents.error
  };
}

export default connect(mapStateToProps)(PastEvents);
