import React from "react";
import { Link } from "react-router-dom";
import CloudinaryImage from "./components/CloudinaryImage";

// In order to show the original high res versions, we include the originals here
// and use cloudinary to do the compressed thumbnails only
import composite2022 from "./assets/2022_composite.jpg";
import composite2021 from "./assets/2021_composite.jpg";
import composite2020 from "./assets/2020_composite.jpg";
import composite2019 from "./assets/2019_composite.jpg";
import composite2018 from "./assets/2018_composite.jpg";
import composite2017 from "./assets/2017_composite.jpg";
import composite2016 from "./assets/2016_composite.jpg";
import composite2015 from "./assets/2015_composite.jpg";
import composite2014 from "./assets/pre_2015_composite.jpg";


import hall_kitchen from "./assets/hall_kitchen.jpg";
import hall_view_from_stage from "./assets/hall_view_from_stage.jpg";
import hall_view_from_stage_full from "./assets/hall_view_from_stage_full.jpg";
import hall_outdoors from "./assets/hall_outdoors.jpg";
import hall_rear_entry from "./assets/hall_rear_entry.jpg";
import hall_view_of_stage from "./assets/hall_view_of_stage.jpg";

const posterImagesHash = {
  2022: {url: composite2022, cloudinary: "2022_composite", id: "2022", title: "2022" },
  2021: {url: composite2021, cloudinary: "2021_composite", id: "2021", title: "2021" },
  2020: {url: composite2020, cloudinary: "2020_composite", id: "2020", title: "2020" },
  2019: {url: composite2019, cloudinary: "2019_composite", id: "2019", title: "2019" },
  2018: {url: composite2018, cloudinary: "2018_composite", id: "2018", title: "2018" },
  2017: {url: composite2017, cloudinary: "2017_composite", id: "2017", title: "2017" },
  2016: {url: composite2016, cloudinary: "2016_composite", id: "2016", title: "2016" },
  2015: {url: composite2015, cloudinary: "2015_composite", id: "2015", title: "2015" },
  2014: {url: composite2014, cloudinary: "pre_2015_composite", id: "2014", title: "2005 to 2014" },
};


const hallImagesHash = {
  hall_kitchen: {url: hall_kitchen, cloudinary: "hall_kitchen", id: "hall_kitchen", title: "Kitchen" },
  hall_view_from_stage_full: {url: hall_view_from_stage_full, cloudinary: "hall_view_from_stage_full", id: "hall_view_from_stage_full", title: "View from the stage" },
  hall_view_from_stage: {url: hall_view_from_stage, cloudinary: "hall_view_from_stage", id: "hall_view_from_stage", title: "View from the stage" },
  hall_view_of_stage: {url: hall_view_of_stage, cloudinary: "hall_view_of_stage", id: "hall_view_of_stage", title: "View of the stage" },
  hall_outdoors: {url: hall_outdoors, cloudinary: "hall_outdoors", id: "hall_outdoors", title: "Outside the front of the Hall" },
  hall_rear_entry: {url: hall_rear_entry, cloudinary: "hall_rear_entry", id: "hall_rear_entry", title: "Rear door entry" },
};

const posterImagesArray = Object.keys(posterImagesHash).map(key => posterImagesHash[key] );

const hallImagesArray = Object.keys(hallImagesHash).map(key => hallImagesHash[key] );

const allPhotosHash = {...hallImagesHash, ...posterImagesHash };

const cloudinaryPosterImages = posterImagesArray.map(
  (poster) => <Link key={poster.id} to={"gallery/" + poster.id} style={{margin: "1rem"}}>
    <CloudinaryImage name={poster.cloudinary} width={240} />
  </Link>
).reverse();

const cloudinaryHallImages = hallImagesArray.map(
  (poster) => <Link key={poster.id} to={"gallery/" + poster.id} style={{margin: "1rem"}}>
    <CloudinaryImage name={poster.cloudinary} width={240} />
  </Link>
);

export {cloudinaryPosterImages, allPhotosHash, cloudinaryHallImages };
