import React, { Component } from "react";
import CloudinaryImage from "./CloudinaryImage";
import ExternalLink from "./ExternalLink";
import { Link } from "react-router-dom";

import { Item, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import MarkdownExternalLink from "./MarkdownExternalLink";

export default class Event extends Component {
  constructor() {
    super();
    this.state = { full_description: false };
    this.toggleDescription = this.toggleDescription.bind(this);
  }

  toggleDescription() {
    this.setState({ full_description: !this.state.full_description });
  }

  render() {
    const {
      title,
      description,
      full_description,
      event_date_description,
      cloudinary_image_name,
      booking_caption,
      booking_url
    } = this.props;

    const toggle_button_text = this.state.full_description
      ? "Show less…"
      : "Show more…";

    return (
      <Item>
        <Item.Image size="medium">
          {cloudinary_image_name && (
            <CloudinaryImage
              name={cloudinary_image_name}
              width={300}
              height={300}
              crop="fit"
              style={{
                // Fight the semantic item image settings
                // but keep their spacing.
                height: "initial",
                width: "initial",
                marginLeft: "auto"
              }}
            />
          )}
        </Item.Image>

        <Item.Content>
          <Item.Header style={{ lineHeight: 1.2, marginBottom: "1em" }}>
            {title}
          </Item.Header>
          <Item.Meta>{event_date_description}</Item.Meta>
          <Item.Description>
            <MarkdownExternalLink>{description}</MarkdownExternalLink>
            {this.state.full_description && (
              <MarkdownExternalLink>{full_description}</MarkdownExternalLink>
            )}
          </Item.Description>
          {!!full_description && (
            <Item.Extra>
              <Link to="/" onClick={this.toggleDescription}>
                {toggle_button_text}
              </Link>
            </Item.Extra>
          )}
          <Item.Extra>
            {!!booking_caption && <strong>{booking_caption}</strong>}

            {!!booking_url && (
              <Button
                as={ExternalLink}
                floated="right"
                href={booking_url}
                alt="Buy tickets online"
              >
                Tickets
              </Button>
            )}
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }
}

Event.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  full_description: PropTypes.string,
  event_date_description: PropTypes.string,
  cloudinary_image_name: PropTypes.string,
  booking_caption: PropTypes.string,
  booking_url: PropTypes.string
};
