/* eslint-disable no-irregular-whitespace */
import React, { Component } from "react";
import ExternalLink from "./ExternalLink";
export default class AboutBlurb extends Component {
  render() {
    return (
      <p>
        The Murrah Hall is a volunteer-run community hall administered by the
        Murrah Hall Preservation Association Inc. on behalf of the Bega Valley
        Shire Council and is one of the many{" "}
        <ExternalLink href="https://www.visitnsw.com/destinations/south-coast/merimbula-and-sapphire-coast">
          {" "}
          destinations on the Sapphire Coast.{" "}
        </ExternalLink>
      </p>
    );
  }
}
