import * as types from '../actions/actionTypes';

export default function currentHallEventsReducer(state = {}, action) {
  switch(action.type) {
    case types.LOAD_CURRENT_HALL_EVENTS_LOADING:
    
      return  {...state, isFetching: true};

    case types.LOAD_CURRENT_HALL_EVENTS_SUCCESS:
      return {...state, hallEvents: action.hallEvents, isFetching: false};

    case types.LOAD_CURRENT_HALL_EVENTS_FAILURE:
      return {...state, error: action.error, isFetching: false};
    default:
      return state;
  }
}

