import HallEventsApi from '../api/HallEventsApi';
import * as types from './actionTypes';

export function loadPastHallEvents() {
  return function(dispatch) {
    dispatch(loadPastHallEventsLoading());
    
    return HallEventsApi.getAllPastHallEvents().then(hallEvents => {
      dispatch(loadPastHallEventsSuccess(hallEvents));
    }).catch(error => {
      dispatch(loadPastHallEventsFailure(error));
    });
  };
}

function loadPastHallEventsLoading(){
  return {type: types.LOAD_PAST_HALL_EVENTS_LOADING};
}

function loadPastHallEventsSuccess(pastHallEvents) {
  return {type: types.LOAD_PAST_HALL_EVENTS_SUCCESS, pastHallEvents};
}

function loadPastHallEventsFailure(error) {
  return {type: types.LOAD_PAST_HALL_EVENTS_FAILURE, error};
}
