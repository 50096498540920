import React, {Component} from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";

export default class CloudinaryImage extends Component {
  render() {
    const {name, width, height, crop, ...otherProps} = this.props;

    // This is not the full list of params.
    // see https://cloudinary.com/documentation/image_transformations#resizing_and_cropping_images
    // Also, we strongly assume width is always provided.
    let cloudinary_params = `w_${width}`;

    if ( height) {
      cloudinary_params += `,h_${height}`;
    }
    if (crop) {
      cloudinary_params += `,c_${crop}`;
    }

    return (
      <Image src={
          `https://res.cloudinary.com/murrahasset/image/upload/${cloudinary_params}/${name}.jpg`
        }
        width={width}
        {...otherProps}
      />
    );
  }
}

CloudinaryImage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  crop: PropTypes.string,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
};
